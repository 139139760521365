<template>
  <div class="page-container">
    <page-header></page-header>
    <div class="dashboard-container">
      <div class="page-card">
        <div class="page-card-boody" style="padding: 20px;" v-if="user">
          <div class="descriptions">
            <div class="descriptions-header">
              <div class="descriptions-title">员工信息</div>
            </div>
            <div class="descriptions-view">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">姓名</span>
                      <span class="descriptions-item-content">{{ user.name }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">性别</span>
                      <span class="descriptions-item-content">{{
                          user.gender == 0 ? '男' : (user.gender == 1 ? '女' : '')
                        }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">手机号</span>
                      <span class="descriptions-item-content">{{ user.phone }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">用户类型</span>
                      <span class="descriptions-item-content">{{
                          {
                            admin: '集团用户',
                            enterprise: '企业用户',
                            distributor: '经销商',
                          }[user.userType]
                        }}</span>
                    </div>
                  </td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">归属组织</span>
                      <span class="descriptions-item-content">
                        <span v-if="user.type == 'admin'">{{app_anme}}</span>
                        <span v-if="user.type == 'enterprise'">{{ user.developers.name }}</span>
                        <span v-if="user.type == 'distributor'">{{ user.distributor.name }}</span>
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">创建时间</span>
                      <span class="descriptions-item-content">
                        {{ user.time }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="descriptions">
            <div class="descriptions-header">
              <div class="descriptions-title">权限管理</div>
            </div>
            <div class="auth-box">
              <el-form :model="permissionForm" :rules="permissionRules" label-width="120px"
                       ref="permissionForm" v-loading="permission_form_loading">
                <div>
                  <div>角色权限</div>
                  <div style="margin-top: 20px;border: 1px solid #eee; padding: 20px;min-height: 400px;">
                    <el-form-item prop="roleIds" label="">
                      <el-checkbox-group v-model="permissionForm.roleIdsArr" @change="roleChange">
                        <el-checkbox v-for="item in role_options" :label="item.id" :key="item.id">{{ item.name }}
                        </el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </div>
                </div>
                <div class="submit-box">
                  <el-form-item style="display: flex;justify-content: flex-end;">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button @click="submit('permissionForm')" type="primary">确 定</el-button>
                  </el-form-item>
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import {
  roleAll,
  userInfo,
  userPermission,
  // developerAndDistributorOptions,
  userPermissionStore,
  organizationTree
} from "@/api/common"
import {APP_NAME} from "@/utils/config";

export default {
  name: "AuthorityEdit",
  data() {
    return {
      app_name: APP_NAME,
      id: null,
      user: null,
      user_permission: null,
      permission_form_loading: false,
      permissionForm: {
        userId: '',
        developersIds: '',
        developersIdsArr: [],
        distributorIdsArr: [],
        distributorIds: '',
        roleIdsArr: [],
        roleIds: '',
      },
      permissionRules: {},
      role_options: [],
      developer_options: [],
      distributor_options: [],
      organizationTree: [],
      checkedIds: [], // 默认选中
      targetData: [], // 已选中的经销商和开发商数据
    }
  },
  components: {
    PageHeader,
  },
  created() {
    this.id = this.$route.params.id
    this.permissionForm.userId = this.$route.params.id
  },
  mounted() {
    this.getUserInfo()
    this.getAllRoles()
    this.getOrganizationTree()
    // this.getDeveloperAndDistributorOptions()
  },
  methods: {
    getUserInfo() {
      let that = this
      userInfo(that.id).then(res => {
        that.user = res
        that.getUserPermission()
      })
    },
    getUserPermission() {
      let that = this
      userPermission(that.id).then(res => {
        that.permissionForm.developersIdsArr =  res.developersIds? res.developersIds.split(','): []
        that.permissionForm.developersIds = res.developersIds
        that.permissionForm.distributorIdsArr = res.distributorIds ?  res.distributorIds.split(','): []
        that.permissionForm.distributorIds = res.distributorIds
        that.permissionForm.roleIdsArr = res.roleIds ? res.roleIds.split(','): []
        that.permissionForm.roleIds = res.roleIds
        let temp1 = []
        for (let item of that.permissionForm.distributorIdsArr) {
          temp1.push('distributor_' + item)
        }
        for (let item of that.permissionForm.developersIdsArr) {
          temp1.push('developer_' + item)
        }
        that.checkedIds = temp1
        // setTimeout(function () {
        //   that.addAction()
        // }, 200)
      })
    },
    getAllRoles() {
      let that = this
      roleAll().then(res => {
        that.role_options = res
      })
    },
    getOrganizationTree() {
      let that = this
      organizationTree(true).then(res => {
        that.organizationTree = res
      })
    },
    //////////////////////////////////////////
    addAction() {
      let that = this
      let selectedKeysLeft = that.$refs.tree.getCheckedKeys();
      if(selectedKeysLeft[0] == 'admin'){
        selectedKeysLeft.splice(0, 1)
      }
      let temp = []
      const pathTo = (list, id) => {
        for (let o of list || []) {
          if (o.id == id) return o
          const res = pathTo(o.children, id)
          if (res) {
            return {
              type: res.type,
              origin_id: res.origin_id,
              id: res.id,
              label: res.label,
              parent_id: res.parent_id
            }
          }
        }
      };
      for (let item of selectedKeysLeft) {
        temp.push(pathTo(that.organizationTree, item))
      }
      that.targetData = temp
    },
    deleteAction() {
      let that = this
      let selectedKeys = that.$refs.rightTree.getCheckedKeys();
      for (let item of selectedKeys) {
        let checkIdIndex = that.checkedIds.indexOf(item)
        if (checkIdIndex > -1) {
          that.checkedIds.splice(checkIdIndex, 1)
        }
      }
      that.$refs.tree.setCheckedKeys(that.checkedIds);
      that.targetData = that.targetData.filter(item => {
        return selectedKeys.indexOf(item.id) < 0
      })
    },
    // 获取开发商经销数据
    getArrData() {
      let that = this, distributor = [], developer = []
      for (let item of that.targetData) {
        if (item.type === 'distributor') {
          distributor.push(item.origin_id)

        } else if (item.type === 'developer') {
          developer.push(item.origin_id)
        }
      }
      that.permissionForm.developersIdsArr = developer
      that.permissionForm.distributorIdsArr = distributor
      that.permissionForm.developersIds = developer.join(',')
      that.permissionForm.distributorIds = distributor.join(',')
    },
    // getDeveloperAndDistributorOptions() {
    //   let that = this
    //   developerAndDistributorOptions().then(res => {
    //     that.developer_options = res.developers
    //     that.distributor_options = res.distributor
    //   })
    // },
    developerChange(val) {
      this.permissionForm.developersIds = val.join(',')
    },
    roleChange(val) {
      this.permissionForm.roleIds = val.join(',')
    },
    distributorChange(val) {
      this.permissionForm.distributorIds = val.join(',')
    },
    cancel() {
      this.$emit('closeView', '/admin/authority')
    },
    submit(ref) {
      let that = this
      that.$refs[ref].validate((valid) => {
        if (!valid) {
          return false
        }
        that.getArrData()
        that.permission_form_loading = true
        userPermissionStore(that.permissionForm).then(() => {
          that.permission_form_loading = false
          that.$message.success('提交成功')
          that.$emit('closeView', '/admin/authority')
        }).catch(() => {
          that.permission_form_loading = false
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.auth-box {
  & /deep/ .el-form-item .el-form-item__content {
    margin-left: unset !important;
  }

  .submit-box {
    margin-top: 20px;
  }
}
</style>